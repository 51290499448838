import React, { useContext, useEffect } from "react";
import MediaQuery from "react-responsive";

import Icon from "../core/Icon";
import Date from "../core/Date";

import { BibleContext } from "../../providers/BibleProvider";
import { SelectedDateContext } from "../../providers/SelectedDateProvider";
import { NavigationContext } from "../../providers/NavigationProvider";

import { MOBILE_WIDTH_BREAKPOINT } from "../../lib/constants";
import {
  formatDate,
  getDayDifference,
  generateCombinedBibleGatewayLink2020,
} from "../../lib/helpers";

import "./index.css";

function DateSelector() {
  const { date, setDate } = useContext(SelectedDateContext);
  let { gospel, NT2, NT, OT, OT2, WP, memorization, advent1, advent2, advent3, advent4, getBibleData } =
    useContext(BibleContext);
  const { side, showOT } = useContext(NavigationContext);
  const formattedDate = formatDate(date);

  if (!showOT) {
    OT = null;
    OT2 = null;
  }

  const passages = [gospel, NT2, OT2, OT, NT, WP, memorization].filter(
    (reference) => {
      return reference;
    }
  );

  useEffect(() => {
    getBibleData(date, side);
  }, [date, side]);

  function handleDayDifference(difference) {
    return () => {
      const updatedDate = getDayDifference(date, difference);
      setDate(updatedDate);

      getBibleData(updatedDate);
    };
  }

  if (!side) {
    return <div></div>;
  }
  return (
    <div className="DateSelector__component">
      <div className="DateSelector__arrow" onClick={handleDayDifference(-1)}>
        <Icon name="ArrowLeft" />
      </div>

      <MediaQuery minWidth={MOBILE_WIDTH_BREAKPOINT}>
        <Date
          onClick={handleDayDifference(-2)}
          date={getDayDifference(formattedDate, -2)}
        />
      </MediaQuery>

      <Date
        onClick={handleDayDifference(-1)}
        date={getDayDifference(formattedDate, -1)}
      />
      {!!passages.length ? (
        <a
          style={{
            textDecoration: "none",
          }}
          href={generateCombinedBibleGatewayLink2020(passages)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Date date={formattedDate} isCenterDate={true} />
        </a>
      ) : (
        <Date date={formattedDate} isCenterDate={true} />
      )}

      <Date
        onClick={handleDayDifference(1)}
        date={getDayDifference(formattedDate, 1)}
      />

      <MediaQuery minWidth={MOBILE_WIDTH_BREAKPOINT}>
        <Date
          onClick={handleDayDifference(2)}
          date={getDayDifference(formattedDate, 2)}
        />
      </MediaQuery>

      <div className="DateSelector__arrow" onClick={handleDayDifference(1)}>
        <Icon name="ArrowRight" />
      </div>
    </div>
  );
}

export default DateSelector;
