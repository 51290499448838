import React from "react";

import "./index.css";

const readingPlan2023 = 
  "/pdf/WACReadingPlan2023.pdf";

const readingPlan2024 =
  "/pdf/WACReadingPlan2024.pdf";

const readingPlan2024Dropbox = "https://www.dropbox.com/scl/fi/shp7nr9vpiv6snlg0lruf/WACReadingPlan2024b.pdf?rlkey=0kcn9hyyddaghplz8u2ak8niv&st=1fzw7r1f&dl=1";

function ReadingPlanForTheYear() {
  return (
    <div className="DownloadReadingPlan__component">
      <a href={readingPlan2024Dropbox} download="wearechurchreadingPDF-2024" rel="noopener noreferrer">
        2024 Reading Plan PDF
      </a>
      <br />
    </div>
  );
}

export default ReadingPlanForTheYear;