import React, { useState } from "react";
import moment from "moment";

// import readingPlan2020 from "../data/year2020/readingPlan.json";
// import westReadingPlan2021 from "../data/year2021/westSide/readingPlan.json";
// import eastReadingPlan2021 from "../data/year2021/eastSide/readingPlan.json";
import readingPlan20222023 from "../data/year2022-2023/readingPlan.json";
import readingPlan20242025 from "../data/year2024-2025/readingPlan.json";
import memorizationPlan from "../data/year2022-2023/memorizationPlan.json";

import { getDayNumberOfYear, capitalizeFirstBookLetter } from "../lib/helpers";
import { EAST, WEST } from "../lib/constants";

export const BibleContext = React.createContext({});

export default function BibleProvider(props) {
  const [NT, setNT] = useState(null);
  const [OT, setOT] = useState(null);
  const [OT2, setOT2] = useState(null);
  const [WP, setWP] = useState(null);
  const [gospel, setGospel] = useState(null);
  const [NT2, setNT2] = useState(null);
  const [videos, setVideos] = useState(videoExamples);
  const [memorization, setMemorization] = useState(null);
  const [advent1, setAdvent1] = useState(null);
  const [advent2, setAdvent2] = useState(null);
  const [advent3, setAdvent3] = useState(null);
  const [advent4, setAdvent4] = useState(null);
  const [advent5, setAdvent5] = useState(null);
  // for just doing a regular card
  const [isRegular, setRegular] = useState(false);
  // for when we do the psalms reflection
  const [isReflection, setReflection] = useState(null);


  function getBibleData(date, side) {
    const year = date.format("YYYY");
    if (year === '2023') {
      get20222023BibleData(date);
    } else if (year === '2024') {
      get20242025BibleData(date);
    }
    // get20222023BibleData(date);
    // if (side === EAST) {
    //   getEastBibleData(date);
    // } else if (side === WEST) {
    //   getWestBibleData(date);
    // } else {
    // }
  }


  function get20242025BibleData(date) {
    // if year is 2022, add 0 to month
    // if year is 2023, add 12 to month
    const year = date.format("YYYY");

    let month = date.month() +1;
    const day = date.date() - 1;
    let dataForSelectedDay = readingPlan20242025[month][day];
    if (dataForSelectedDay) {
      let videos = []
      if (dataForSelectedDay.gospel_video_url) {
      videos.push(
        {
          type: "gospel",
          label: dataForSelectedDay.gospel_video_label,
          src: dataForSelectedDay.gospel_video_url,
        })
      }
      if (dataForSelectedDay.nt_video_url) {
        videos.push({
          type: "nt",
          label: dataForSelectedDay.nt_video_label,
          src: dataForSelectedDay.nt_video_url,
        })
      }

      // check if it's a psalms reading
      if ( dataForSelectedDay.gospel.indexOf("Psalms") !== -1) {
        console.log('found Psalms');
        setReflection(true);
      } else {
        console.log('counld not find Psalms');
        setReflection(false);
      }
      // since we're in 2024/2025, setting this to the regular card
      setRegular(true);

      setNT2(capitalizeFirstBookLetter(dataForSelectedDay.gospel));
      setOT(dataForSelectedDay.ot1);
      setVideos(videos);
    } else {
      setGospel(null);
      setNT2(null);
      setOT(null);
      setOT2(null);
      setAdvent1(null);
      setAdvent2(null);
      setAdvent3(null);
      setAdvent4(null);
      setAdvent5(null);
    }

    setWP(null);
    setNT(null);
  }


  function get20222023BibleData(date) {
    // if year is 2022, add 0 to month
    // if year is 2023, add 12 to month
    const year = date.format("YYYY");

    let month = date.month() + 1;
    if (year === '2023') {
      month += 12;
    }
    const day = date.date();
    let dataForSelectedDay = readingPlan20222023[month][day];
    if (dataForSelectedDay) {
      const videos = [
        {
          type: "nt",
          label: dataForSelectedDay.nt_video_label,
          src: dataForSelectedDay.nt_video_url,
        },
        {
          type: "ot1",
          label: dataForSelectedDay.ot1_video_label,
          src: dataForSelectedDay.ot1_video_url,
        },
      ];
      setNT2(capitalizeFirstBookLetter(dataForSelectedDay.nt));
      setOT(dataForSelectedDay.ot1);
      setVideos(videos);
      if (dataForSelectedDay.adventReadVerses) {
        setAdvent1(dataForSelectedDay.adventReadVerses);
        setAdvent2(dataForSelectedDay.adventReadDesc);
        setAdvent3(dataForSelectedDay.adventReflection);
        setAdvent4(dataForSelectedDay.adventPrayer);
        setAdvent5(null);
      }
      if (dataForSelectedDay.adventMeditation) {
        setAdvent5(dataForSelectedDay.adventMeditation);
        setAdvent1(null);
      }
    } else {
      setGospel(null);
      setNT2(null);
      setOT(null);
      setOT2(null);
      setAdvent1(null);
      setAdvent2(null);
      setAdvent3(null);
      setAdvent4(null);
      setAdvent5(null);
    }

    setWP(null);
    setNT(null);

    // Setting up memorization
    let startDate = moment("2022-01-01", "YYYY-MM-DD");
    let difference = date.diff(startDate, "days");

    if (difference >= 0) {
      let dataForMemorization = memorizationPlan[difference];
      setMemorization(dataForMemorization || null);
    }
  }

  // function getEastBibleData(date) {
  //   const month = date.month() + 1;
  //   const day = date.date();
  //   let dataForSelectedDay = eastReadingPlan2021[month][day];

  //   if (dataForSelectedDay) {
  //     const videos = [
  //       {
  //         type: "gospel",
  //         label: dataForSelectedDay.gospel_video_label,
  //         src: dataForSelectedDay.gospel_video_url,
  //       },
  //       {
  //         type: "nt",
  //         label: dataForSelectedDay.nt_video_label,
  //         src: dataForSelectedDay.nt_video_url,
  //       },
  //       {
  //         type: "ot1",
  //         label: dataForSelectedDay.ot1_video_label,
  //         src: dataForSelectedDay.ot1_video_url,
  //       },
  //       {
  //         type: "ot2",
  //         label: dataForSelectedDay.ot2_video_label,
  //         src: dataForSelectedDay.ot2_video_url,
  //       },
  //     ];

  //     setGospel(capitalizeFirstBookLetter(dataForSelectedDay.gospel));
  //     setNT2(capitalizeFirstBookLetter(dataForSelectedDay.nt));
  //     setOT(dataForSelectedDay.ot1);
  //     setOT2(dataForSelectedDay.ot2);
  //     setVideos(videos);
  //   } else {
  //     setGospel(null);
  //     setNT2(null);
  //     setOT(null);
  //     setOT2(null);
  //   }

  //   setWP(null);
  //   setNT(null);
  // }

  const value = {
    NT,
    OT,
    OT2,
    WP,
    gospel,
    NT2,
    videos,
    memorization,
    advent1,
    advent2,
    advent3,
    advent4,
    advent5,
    getBibleData,
    isReflection,
    isRegular
  };

  return <BibleContext.Provider value={value} {...props} />;
}

const videoExamples = [
  {
    label: "The Birth of Jesus - Gospel of Luke Ch. 1-2",
    src: "https://youtube.com/embed/_OLezoUvOEQ",
  },
  {
    label: "The Book of Genesis Overview - Part 1 of 2",
    src: "https://youtube.com/embed/KOUV7mWDI34",
  },
];
