import React, { useState, useEffect } from "react";

export const NavigationContext = React.createContext({});

export default function NavigationProvider(props) {
  const initialShowOT = true;
  const [side, setSide] = useState(null);
  const [showOT, setShowOT] = useState(initialShowOT);

  useEffect(() => {
    const localSide = localStorage.getItem("side");
    setSide(localSide);
  }, []);

  const toggleShowOT = () => {
    setShowOT(!showOT);
    localStorage.setItem("showOT", !showOT);
  };

  const value = {
    side,
    setSide,
    showOT,
    setShowOT,
    toggleShowOT,
  };

  return <NavigationContext.Provider value={value} {...props} />;
}
