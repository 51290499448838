import React, { memo, useContext } from "react";

import { BibleContext } from "../../providers/BibleProvider";
import { NavigationContext } from "../../providers/NavigationProvider";

import { generateBibleGatewayLink } from "../../lib/helpers";

import "./index.css";


const week2meditation = (
  <div>
    <h3>Advent Meditation</h3>
    <div className="BiblePassages__desc">
      <div>O come, O come, Emmanuel </div>
      <div>And ransom captive Israel </div>
      <div>That mourns in lonely exile here Until the Son of God appear</div>
      <div>Rejoice! Rejoice!</div>
      <div>Emmanuel shall come to thee, O Israel.</div>
    <p></p>

    <div>O come, Thou Rod of Jesse, free Thine own from Satan’s tyranny</div>
    <div>From depths of Hell Thy people save And give them victory</div>
    <div>o’er the grave </div>
    <div>Rejoice! Rejoice!</div>
    <div>Emmanuel shall come to thee, O Israel.</div>
    <p></p>

    <div>O come, Thou Key of David, come, And open wide our heavenly</div>
    <div>home; Make safe the way that leads on high, And close the</div>
    <div>path to misery. </div>
    <div>Rejoice! Rejoice!</div>
    <div>Emmanuel shall come to thee, O Israel.</div>
    <p></p>

    <div>O come, O come, Thou Lord of might, Who to Thy tribes, on</div>
    <div>Sinai’s height, In ancient times did’st give the Law,</div>
    <div>In cloud, and majesty and awe.</div>
    <div>Rejoice! Rejoice!</div>
    <div>Emmanuel shall come to thee, O Israel.</div>
    <p></p>

    <div>— Latin hymn from the 12th Century, translated into English by John Mason Neale, 1851</div>
 <p></p>

    <div>Note: Emmanuel is derived from the Greek and Immanuel is derived from the Hebrew. Both spellings mean “God with us”.</div>
  </div>
  <div className="BiblePassages__footnote">Advent Devotional from Gospel In Life</div>
  </div>
)

const week3meditation = (
<div>
<h3>Advent Meditation</h3>
<div className="BiblePassages__desc">
  <div>Thou who wast rich beyond all splendour, All for love’s</div>
  <div>sake became poor;</div>
  <div>Thrones for a manger didst surrender, Sapphire-paved</div>
  <div>courts for stable floor.</div>
  <div>Thou who wast rich beyond all splendour, All for love’s</div>
  <div>sake became poor.</div>
  <p></p>

  <div>Thou who art God beyond all praising, All for love’s sake</div>
  <div>becamest man;</div>
  <div>Stooping so low, but sinners raising Heavenwards</div>
  <div>by thine eternal plan.</div>
  <div>Thou who art God beyond all praising, All for love’s sake</div>
  <div>Becamest Man.</div>
  <p></p>

  <div>Thou who art love beyond all telling, Saviour and King,</div>
  <div>we worship thee.</div>
  <div>Emmanuel, within us dwelling, Make us what thou</div>
  <div>wouldst have us be.</div>
  <div>Thou who art love beyond all telling, Saviour and King,</div>
  <div>we worship thee.</div>
  <p></p>

  <div>—Frank Houghton, 1934</div>
  </div>
  <div className="BiblePassages__footnote">Advent Devotional from Gospel In Life</div>
</div>
)

const week4meditation = (
  <div>
    <h3>Advent Meditation</h3>
<div className="BiblePassages__desc">
    <div>Hark! the herald angels sing,</div>
    <div>“Glory to the newborn King,</div>
    <div>peace on earth, and mercy mild,</div>
    <div>God and sinners reconciled!”</div>
    <div>Joyful, all ye nations rise,</div>
    <div>join the triumph of the skies;</div>
    <div>with the angelic host proclaim,</div>
    <div>“Christ is born in Bethlehem!”</div>
    <div>Hark! the herald angels sing,</div>
    <div>“Glory to the newborn King!”</div>
    <p></p>
    <div>Christ, by highest heaven adored;</div>
    <div>Christ, the everlasting Lord;</div>
    <div>late in time behold him come,</div>
    <div>offspring of a virgin’s womb.</div>
    <div>Veiled in flesh the Godhead see;</div>
    <div>hail the incarnate Deity,</div>
    <div>pleased as man with men</div>
    <div>to dwell, Jesus, our Emmanuel.</div>
    <div>Hark! the herald angels sing,</div>
    <div>“Glory to the newborn King!”</div>
    <p></p>
    <div>Hail the heaven-born Prince of Peace!</div>
    <div>Hail the Sun of Righteousness!</div>
    <div>Light and life to all he brings,</div>
    <div>risen with healing in his wings.</div>
    <div>Mild he lays his glory by,</div>
    <div>born that men no more may die,</div>
    <div>born to raise the sons of earth,</div>
    <div>born to give them second birth.</div>
    <div>Hark! the herald angels sing,</div>
    <div>“Glory to the newborn King!”</div>
    <p></p>
    <div>—Charles Wesley, 1739</div>
    </div>
    <div className="BiblePassages__footnote">Advent Devotional from Gospel In Life</div>
  </div>
  )

function showAdventStuff(advent1, advent2, advent3, advent4, advent5) {
  console.log('advent5: ', advent5)
  if (advent5) {
    switch (advent5) {
      case 'week2':
        return week2meditation;
      case 'week3':
        return week3meditation;
      case 'week4':
        return week4meditation;
    }
  }
  if (advent1) {
    return (
      <div>
      <h3>Advent Reading</h3>
        <div className="BiblePassages__text">
          <a
            href={generateBibleGatewayLink(advent1)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {advent1}
          </a>
        </div>
        <div className="BiblePassages__desc">
          {advent2}
        </div>
        <h3>Advent Reflection</h3>
          <div className="BiblePassages__desc">
              {advent3}
          </div>
        <h3>Advent Prayer</h3>
          <div className="BiblePassages__desc">
              {advent4}
        </div>
        <div className="BiblePassages__footnote">Advent Devotional from Gospel In Life</div>
      </div>
    )
  }
}

function showMemorization(memorization) {
  return (
    <div>
            <h3>Memorization</h3>
      <a
        href={generateBibleGatewayLink(memorization)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {memorization}
      </a>
    </div>
  )
}

function BiblePassages() {
  let { gospel, NT2, OT, OT2, NT, WP, memorization, advent1, advent2, advent3, advent4, advent5 } = useContext(BibleContext);
  const { showOT } = useContext(NavigationContext);

  if (!showOT) {
    OT = null;
    OT2 = null;
  }

  const passages = [gospel, NT2, OT2, OT, NT, WP].filter((passage) => {
    return passage;
  });

  if (!passages.length && !memorization) {
    return <div></div>;
  }
  return (
    <div className="BiblePassages__component">      
      <h3>Reading</h3>
      {passages.map((passage, index) => (
        <div className="BiblePassages__text" key={index}>
          <a
            href={generateBibleGatewayLink(passage)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {passage}
          </a>
        </div>
      ))}
      {memorization && showMemorization(memorization)}
      {(advent1 || advent5) && showAdventStuff(advent1, advent2, advent3, advent4, advent5)}
    </div>
  );
}

export default BiblePassages;
