import React, { useContext, useEffect, useState } from "react";
import Button from "../core/Button";
import { NavigationContext } from "../../providers/NavigationProvider";
import { EAST, WEST } from "../../lib/constants";
import "./index.css";

import readScriptureLogo from "../../images/readScriptureLogo.png";

function Navigation() {
  const { side, setSide  } = useContext(NavigationContext);

  // Forcing us to default to the East side:
  useEffect(() => {
    setSide(EAST);
    localStorage.setItem("side", EAST);
  });

  return (
    <div className="Navigation__component">
      <div className="Navigation__items">
        <div className="Navigation__logo">
          <img src={readScriptureLogo} height={40} alt="read scripture logo" />
          <div className="Navigation__title"> WAC Reading</div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
