import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";

import NavigationProvider from "./providers/NavigationProvider";
import SelectedDateProvider from "./providers/SelectedDateProvider";
import BibleProvider from "./providers/BibleProvider";

import { unregister } from "./registerServiceWorker";

ReactDOM.render(
  <NavigationProvider>
    <SelectedDateProvider>
      <BibleProvider>
        <App />
      </BibleProvider>
    </SelectedDateProvider>
  </NavigationProvider>,
  document.getElementById("root")
);
unregister();
