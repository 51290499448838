import moment from "moment";

// Date formatting and logic
export function formatDate(date, dateFormat = "MM-DD-YYYY") {
  return moment(date, "MM-DD-YYYY").format(dateFormat);
}

export function getDayDifference(date, numDays = 1) {
  return moment(date, "MM-DD-YYYY").add({ day: numDays });
}

export function getDayNumberOfYear(date) {
  return parseInt(formatDate(date, "DDDD")) - 1;
}

export function getIsEndOfMonth(date) {
  return moment(date).date() >= 26;
}

// Verse reference formatting
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizeFirstBookLetter(string) {
  if (string.split(" ").length > 2) {
    const bookArray = string.split(" ");
    bookArray[1] = capitalizeFirstLetter(bookArray[1]);
    return bookArray.join(" ");
  }
  return capitalizeFirstLetter(string);
}

// Generate Bible links

export function generateBibleGatewayLink(reference = "John 1") {
  let urlifiedPassage = reference.replace(/\s/g, "+");
  return `https://www.biblegateway.com/passage/?search=${urlifiedPassage}&version=ESV`;
}

export function generateCombinedBibleGatewayLink2020(references) {
  let passages = references.filter((reference) => {
    return reference;
  });
  if (!passages.length) {
    return "";
  }

  const urlString = passages
    .map((passage) => {
      return passage.replace(/\s/g, "+") + "%3B+";
    })
    .join("");

  return `https://www.biblegateway.com/passage/?search=${urlString}&version=ESV`;
}
