import React from "react";

import "./App.css";
import DateSelector from "./components/DateSelector/index";
import BiblePassages from "./components/BiblePassages/index";
import ReadingPlanForTheYear from "./components/DownloadReadingPlan/index";
import Navigation from "./components/Navigation";
import Cards from "./components/Cards";
import "./index.css";

const Version = () => {
  return <div className="version">v5.0</div>;
};

const Credits = () => {
  return (
    <div className="version">
      <a href="http://www.wearechurch.com">wearechurch</a>
    </div>
  );
};

function App() {
  return (
    <div className="App__component">
      <Navigation />
      <DateSelector />

      <BiblePassages />
      <ReadingPlanForTheYear />

      <Cards />

      <Version />
      <Credits />
    </div>
  );
}

export default App;
