import React from "react";

import { formatDate } from "../../../lib/helpers";

import "./index.css";

function Date({
  date = "01-01-2018",
  onClick = () => {},
  isCenterDate = false,
}) {
  const dayAndDate = formatDate(date, "ddd M/DD");
  const [dayOfWeek, calendarDate] = dayAndDate.split(" ");
  return (
    <div
      className={`Date__component ${isCenterDate ? "Date__isCenterDate" : ""}`}
      onClick={onClick}
    >
      <div
        className={`Date__dayOfWeek ${
          isCenterDate ? "Date__dayOfWeek__isCenterDate" : ""
        }`}
      >
        {dayOfWeek}
      </div>
      <div
        className={`Date__calendarDate ${
          isCenterDate ? "Date__calendarDate__isCenterDate" : ""
        }`}
      >
        {calendarDate}
      </div>
    </div>
  );
}

export default Date;
