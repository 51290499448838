export const COLORS = {
  BLUE: "#3F83F8",
  LIGHT_GRAY: "#97A6BA",
  GRAY: "#A9A9A9",
  DARK_GRAY: "#475569",
  BLUE_GREY: "#C3DDFD",
};

export const MOBILE_WIDTH_BREAKPOINT = 450;

export const MID_WIDTH_BREAKPOINT = 550;

export const MOBILE_HEIGHT_BREAKPOINT = 850;

export const EAST = "East";

export const WEST = "West";
