import React, { useState } from "react";
import moment from "moment";

export const SelectedDateContext = React.createContext({});

export default function SelectedDateProvider(props) {
  const currentDate = moment(new Date(), "MM-DD-YYYY");

  const [date, setDate] = useState(currentDate);

  const value = {
    date,
    setDate,
    currentDate,
  };

  return <SelectedDateContext.Provider value={value} {...props} />;
}
