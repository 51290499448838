import React from "react";

import { COLORS } from "../../../lib/constants";

function Icon({ name = "", color = COLORS.BLUE }) {
  return name === "ArrowLeft" ? (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2501 23.25L1.53006 12.53C1.46039 12.4604 1.40513 12.3778 1.36742 12.2869C1.32971 12.1959 1.3103 12.0984 1.3103 12C1.3103 11.9016 1.32971 11.8041 1.36742 11.7131C1.40513 11.6222 1.46039 11.5396 1.53006 11.47L12.2501 0.75"
        stroke="#3F83F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0.75L12.22 11.47C12.2897 11.5396 12.3449 11.6222 12.3826 11.7131C12.4203 11.8041 12.4398 11.9016 12.4398 12C12.4398 12.0984 12.4203 12.1959 12.3826 12.2869C12.3449 12.3778 12.2897 12.4604 12.22 12.53L1.5 23.25"
        stroke="#3F83F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
