import React, { useState, useLayoutEffect } from "react";
import MediaQuery from "react-responsive";

import Text, { TEXT_TYPES } from "../Text";

import reflectionImg from "../../../images/reflection.png";
import endOfMonthImg from "../../../images/biblecoffee.png";

import { MID_WIDTH_BREAKPOINT } from "../../../lib/constants";

import "./index.css";

const IFRAME_WIDTH = 480;
const IFRAME_HEIGHT = 270;

function Card({ type, videoText, videoUrl }) {
  const [iframeWidth, setIframeWidth] = useState(window.innerWidth - 32);
  useLayoutEffect(() => {
    function updateWidth() {
      setIframeWidth(window.innerWidth - 32);
    }

    window.addEventListener("resize", updateWidth);
  }, [iframeWidth]);

  if (!type) {
    return <div />;
  }

  const isEndOfMonth = type === CARD_TYPES.END_OF_MONTH;
  const isIntro = type === CARD_TYPES.INTRO;
  const isReadScripture = type === CARD_TYPES.READ_SCRIPTURE;
  const isReflection = type === CARD_TYPES.REFLECTION;
  const isPsalmReflection = type === CARD_TYPES.PSALMREFLECTION;

  const readScriptureText = (
    <div className="Card__label">
      <Text type={TEXT_TYPES.HEADER}>{videoText}</Text>
      <div className="Card__label__author__section">
        <Text type={TEXT_TYPES.AUTHOR}>The Bible Project</Text>
      </div>
    </div>
  );

  const reflectionText = (
    <div className="Card__label">
      <Text type={TEXT_TYPES.HEADER}>Daily reflection</Text>
      <div className="Card__label__section">
        <Text type={TEXT_TYPES.DEFAULT} bold={true}>
          Pray for Insight:
        </Text>
        <Text>
          {" "}
          What are the key truths God intends to communicate? Ask God to help
          you understand what this passage means to you.
        </Text>
      </div>
      <div className="Card__label__section">
        <Text type={TEXT_TYPES.DEFAULT} bold={true}>
          God Being Revealed:
        </Text>
        <Text>
          {" "}
          What does this tell you about God and who He is? Spend time
          worshipping Him accordingly.
        </Text>
      </div>
      <div className="Card__label__section">
        <Text type={TEXT_TYPES.DEFAULT} bold={true}>
          Now Let's Respond:
        </Text>
        <Text>
          {" "}
          What does God want you to obey or do differently in your life today?
          Ask God to help you change.
        </Text>
      </div>
    </div>
  );

  const psalmReflectionText = (
    <div className="Card__label">
      <Text type={TEXT_TYPES.HEADER}>Psalm reflection</Text>
      <div className="Card__label__section">
        <Text>1) Catch up on any missed reading.</Text>
      </div>
      <div className="Card__label__section">
        <Text>
          2) Meditate and study deeper on passages that have impacted you this
          month.
        </Text>
      </div>
      <div className="Card__label__section">
        <Text>
          3) Pray through the Psalms passages
        </Text>
      </div>
    </div>
  );

  const endOfMonthText = (
    <div className="Card__label">
      <Text type={TEXT_TYPES.HEADER}>End of the Month</Text>
      <div className="Card__label__section">
        <Text>1) Catch up on any missed reading.</Text>
      </div>
      <div className="Card__label__section">
        <Text>
          2) Meditate and study deeper on passages that have impacted you this
          month.
        </Text>
      </div>
      <div className="Card__label__section">
        <Text>
          3) Start to prepare for and study backgrounds for next month's
          Scriptural reading.
        </Text>
      </div>
    </div>
  );

  const introText = (
    <div className="Card__label">
      <Text type={TEXT_TYPES.HEADER}>Reading Plans</Text>
      <div className="Card__label__section">
        <Text>
          This year, West Side and East Side will have their own reading plans.
          Please select your side to view your plan!
        </Text>
      </div>
      <div className="Card__label__section">
        <Text>
          <div>East Side Elders: Sean, Matt, and Kevin Shedden </div>
          <div>West Side Elders: Pira, Rob and Kevin Kim </div>
        </Text>
      </div>
      <div className="Card__label__section">
        <Text>
          If you're not sure what side you are on, please ask your pastor.{" "}
        </Text>
      </div>
    </div>
  );

  //TODO: clean up logic so that card options are easier to select
  return (
    <div className="Card__component">
      {(isReadScripture || isIntro) && (
        <div style={{ overflow: "hidden", position: "relative" }}>
          <MediaQuery maxWidth={MID_WIDTH_BREAKPOINT}>
            <iframe
              width={iframeWidth}
              height={(iframeWidth / IFRAME_WIDTH) * IFRAME_HEIGHT}
              src={videoUrl}
              title={videoText}
            />
          </MediaQuery>
          <MediaQuery minWidth={MID_WIDTH_BREAKPOINT + 1}>
            <iframe
              width={IFRAME_WIDTH}
              height={IFRAME_HEIGHT}
              src={videoUrl}
              title={videoText}
            />
          </MediaQuery>
        </div>
      )}
      {isReflection && (
        <img src={reflectionImg} width="100%" alt="reflection" />
      )}
      {isEndOfMonth && (
        <img src={endOfMonthImg} width="100%" alt="reflection" />
      )}
      {isPsalmReflection && (
        <img src={reflectionImg} width="100%" alt="reflection" />
      )}
      {isReadScripture && readScriptureText}
      {isEndOfMonth && endOfMonthText}
      {isPsalmReflection && psalmReflectionText}
      {isReflection && reflectionText}
      {isIntro && introText}
    </div>
  );
}

export const CARD_TYPES = {
  END_OF_MONTH: "END_OF_MONTH",
  INTRO: "INTRO",
  READ_SCRIPTURE: "READ_SCRIPTURE",
  REFLECTION: "REFLECTION",
  PSALMREFLECTION: "PSALMREFLECTION"
};

export default Card;
