import React, { useContext } from "react";

import Card, { CARD_TYPES } from "../../components/core/Card";

import { SelectedDateContext } from "../../providers/SelectedDateProvider";
import { NavigationContext } from "../../providers/NavigationProvider";
import { BibleContext } from "../../providers/BibleProvider";

import { getIsEndOfMonth } from "../../lib/helpers";
import { EAST, WEST } from "../../lib/constants";

import "./index.css";

function Cards() {
  const { date } = useContext(SelectedDateContext);
  const { side, showOT } = useContext(NavigationContext);
  const { videos, isReflection, isRegular } = useContext(BibleContext);
  const isEndOfMonth = getIsEndOfMonth(date);

  if (side === WEST && videos.length) {
    return (
      <div className="Cards__component">
        {videos.map((video, index) => (
          <Card
            type={CARD_TYPES.READ_SCRIPTURE}
            videoText={videos[index].label}
            videoUrl={videos[index].src}
            key={index}
          />
        ))}
      </div>
    );
  } else if (side === EAST) {
    if (isReflection) {
      return (
        <div className="Cards__component">
          <Card type={CARD_TYPES.PSALMREFLECTION} />
          {videos.map((video, index) => {
            return (
              <Card
                type={CARD_TYPES.READ_SCRIPTURE}
                videoText={videos[index].label}
                videoUrl={videos[index].src}
                key={index}
              />
            );
          })}
        </div>
      );
    } else if (isRegular) {
      return (
        <div className="Cards__component">
          <Card type={CARD_TYPES.REFLECTION} />
          {videos.map((video, index) => {
            return (
              <Card
                type={CARD_TYPES.READ_SCRIPTURE}
                videoText={videos[index].label}
                videoUrl={videos[index].src}
                key={index}
              />
            );
          })}
        </div>
      );
    }


    if (isEndOfMonth) {
      return (
        <div className="Cards__component">
          <Card type={CARD_TYPES.END_OF_MONTH}></Card>
        </div>
      );
    } else {
      return (
        <div className="Cards__component">
          <Card type={CARD_TYPES.REFLECTION} />
          {videos.map((video, index) => {
            if ((video.type === "ot1" || video.type === "ot2") && !showOT) {
              return null;
            } else {
              return (
                <Card
                  type={CARD_TYPES.READ_SCRIPTURE}
                  videoText={videos[index].label}
                  videoUrl={videos[index].src}
                  key={index}
                />
              );
            }
          })}
        </div>
      );
    }
  }

  // if no sides are chosen, display selection screen
  return (
    <div className="Cards__component Intro">
      <Card
        type={CARD_TYPES.INTRO}
        videoUrl="https://youtube.com/embed/plSNIwhAn5o"
      ></Card>
    </div>
  );
}

export default Cards;
