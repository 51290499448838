import React from "react";

import "./index.css";

function Text({ type = TEXT_TYPES.DEFAULT, bold = false, children }) {
  return (
    <span className={`Text__component ${type} ${bold ? "emphasize" : ""}`}>
      {children}
    </span>
  );
}

export const TEXT_TYPES = {
  DEFAULT: "",
  HEADER: "header",
  AUTHOR: "author",
};

export default Text;
